import { Outlet } from "react-router";
import { Link } from "react-router-dom";

export function Layout() {
  return (
    <div>
      <nav>
        <h1>Kayric's Wedding</h1>
        <ul>
          <li>
            <Link to="/event">Event Details</Link>
          </li>
          <li>
            <Link to="/our-story">Our Story</Link>
          </li>
          <li>
            <Link to="/rsvp">RSVP</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
}
