import * as bcrypt from "bcryptjs";

export interface ResponseAuthenticated {
  isAuthenticated: true;
  sessionToken: string;
}

interface ResponseUnauthenticated {
  isAuthenticated: false;
}

export const isAuthenticated = (
  response: ResponseAuthenticated | ResponseUnauthenticated
): response is ResponseAuthenticated => response.isAuthenticated;

export const signIn = async (
  password: string
): Promise<ResponseAuthenticated | ResponseUnauthenticated> => {
  const target = "$2a$10$NJhbMgZ1Z4VM3NeziMRUkut2VWPEd0lnlKm92026yzFGe6r92tyIe";

  if (bcrypt.compareSync(password, target)) {
    return { isAuthenticated: true, sessionToken: "123" };
  }

  return { isAuthenticated: false };
};

export const signOut = async (): Promise<boolean> => true;

// const hash = async (secret: string): Promise<string> => {
//   const rounds = 10;
//   return new Promise((resolve, reject) => {
//     bcrypt.genSalt(rounds, (err, salt) => {
//       if (err) {
//         reject("Could not generate a salt");
//       }

//       bcrypt.hash(secret, salt, (err, hash) => {
//         if (err) {
//           reject("Could not generate hash");
//         }

//         resolve(hash);
//       });
//     });
//   });
// };
