import * as React from "react";
import "./Input.scss";

export function Input({
  classes,
  name,
  onChange,
  onKeyUp,
  placeholder,
  type,
  value,
}: {
  classes?: string[];
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string;
}) {
  return (
    <input
      className={[...(classes || []), "input"].join(" ")}
      name={name}
      onChange={onChange}
      onKeyUp={onKeyUp}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  );
}
