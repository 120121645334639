import * as React from "react";
import {
  ResponseAuthenticated,
  isAuthenticated,
  signIn,
  signOut,
} from "../simple-auth-client";

interface AuthContextType {
  session: ResponseAuthenticated | null;
  signin: (password: string) => Promise<boolean>;
  signout: () => Promise<boolean>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = React.useState<any>(null);

  const signin = async (password: string): Promise<boolean> => {
    const response = await signIn(password);

    if (isAuthenticated(response)) {
      setSession({ sessionToken: response.sessionToken });
      return true;
    }

    return false;
  };

  const signout = async (): Promise<boolean> => {
    setSession(null);
    return signOut();
  };

  const value = { session, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): AuthContextType => {
  return React.useContext(AuthContext);
};
