import * as React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../components/AuthProvider";
import { Title } from "../components/Title";
import { Header } from "../components/Header";
import { Input } from "../components/Input";
import { Button } from "../components/Button";

import "./Login.scss";

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [password, setPassword] = useState<any>("");

  const from = location.state?.from?.pathname || "/";

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key !== "Enter") {
      return;
    }

    handleSubmit();
  };

  async function handleSubmit() {
    await auth.signin(password);
    navigate(from, { replace: true });
  }

  return (
    <div className="login">
      <div className="login__backdrop">
        <div className="login__body">
          <Title>Site Under Construction</Title>
          <Header>Check back again soon!</Header>
          <div className="login_password-area">
            <Input
              classes={["login__password-input"]}
              placeholder="Password"
              name="password"
              type="password"
              value={password}
              onKeyUp={handleKeyPress}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              classes={["login__password-button"]}
              onClick={handleSubmit}
              text={"Go!"}
            />
          </div>
        </div>
        <div className="login__footer">
          <p>Made with 💖 by Kayla and Eric</p>
        </div>
      </div>
    </div>
  );
}
