import "./Button.scss";

export function Button({
  text,
  classes,
  onClick,
}: {
  text?: string;
  classes?: string[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <button
      className={[...(classes || []), "button"].join(" ")}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
