import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.scss";

import { AuthProvider } from "./components/AuthProvider";
import { RequireAuth } from "./components/RequireAuth";

import { EventDetails } from "./routes/EventDetails";
import { Layout } from "./routes/Layout";
import { Login } from "./routes/Login";
import { OurStory } from "./routes/OurStory";
import { Rsvp } from "./routes/Rsvp";

function App() {
  return (
    <div className="app">
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="/event" element={<EventDetails />} />
            <Route path="/rsvp" element={<Rsvp />} />
            <Route path="/our-story" element={<OurStory />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
